import {
	Card,
	CardActions,
	CardContent,
	Typography,
	Chip,
	Stack,
	Alert,
	Button,
} from '@mui/material';
import { FC } from 'react';
import { useAppDispatch } from '@app/hooks/useReduxToolkit';
import AddOrEditUserDialog from './AddOrEditUserDialog';
import SendIcon from '@mui/icons-material/Send';
import UserDialog from '@app/shared/components/dialog/UserDialog';
import { resendPartnerUserInvite } from 'state-services';
import { RhymeUser, getRolesDisplay } from 'type-declarations';
import { deleteUserDialog } from '../constants';

type Props = {
	userId: string;
	users: RhymeUser[];
	loading: boolean;
	submitUser: (user: RhymeUser) => void;
	removeUser: (email: string) => void;
};

const UserDetails: FC<Props> = ({ userId, users, loading, submitUser, removeUser }) => {
	const dispatch = useAppDispatch();

	let user = users.find((user: RhymeUser) => user.id === userId);
	const getRoleTypes = () => {
		if (user?.roles !== undefined && user?.roles.length > 0) {
			return getRolesDisplay(user.roles);
		}
		return [];
	};
	if (user !== undefined && !user.userName) {
		user = { ...user };
	}

	const notConfirmedUser = (user: RhymeUser | undefined) => {
		const isConfirmed = user?.isConfirmed;
		if (user !== undefined && !isConfirmed) {
			return (
				<Alert
					severity="info"
					sx={{ marginBottom: 1 }}
					action={
						<Button startIcon={<SendIcon />} onClick={() => sendInvitation()}>
							Send New Invite
						</Button>
					}
				>
					This user has not confirmed their details.
				</Alert>
			);
		}
		return <></>;
	};

	const sendInvitation = () => {
		if (user !== undefined && user.userName !== undefined) {
			dispatch(resendPartnerUserInvite({ userName: user.userName }));
		}
	};

	const disableDeleteDialog = () => {
		return (
			<UserDialog
				user={{ ...user! }}
				btnText={deleteUserDialog.btnText}
				btnIcon={deleteUserDialog.btnIcon}
				title={deleteUserDialog.title}
				description={deleteUserDialog.description}
				callback={removeUser}
			/>
		);
	};

	return (
		<>
			{notConfirmedUser(user)}
			<Card variant="outlined">
				{user && (
					<CardContent>
						<Typography variant="h5" component="h5">
							{user.firstName} {user.lastName}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{user.userName}
						</Typography>
						<Stack direction="row" spacing={1} marginY={1}>
							{getRoleTypes().map((role, index) => (
								<Chip label={role} key={index} />
							))}
						</Stack>
					</CardContent>
				)}

				<CardActions sx={{ justifyContent: 'flex-end', display: 'flex' }}>
					<AddOrEditUserDialog user={user!} isEdit submitUser={submitUser} loading={loading} />
					{disableDeleteDialog()}
				</CardActions>
			</Card>
		</>
	);
};

export default UserDetails;
