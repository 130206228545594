import { RhymeUser } from 'type-declarations';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm } from 'react-hook-form';
import { getUserFormSchema } from '../validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { UserRoleAutcomplete } from './UserRoleAutocomplete';

interface UserFormInput {
	fieldKey: 'id' | 'userName' | 'firstName' | 'lastName' | 'roles';
	label: string;
	gridXs: number;
	props?: any;
}

type Props = {
	user?: RhymeUser;
	isEdit: boolean;
	loading: boolean;
	submitUser: (user: RhymeUser) => void;
};

const AddOrEditUserDialog: FC<Props> = ({ user, isEdit, loading, submitUser }) => {
	const { activeEntity } = useAppSelector((state) => state.user);
	const { name, id, approvedEmailDomains } = activeEntity;
	const dialogTitleText = isEdit ? 'Modify User' : 'Add User';
	const buttonIcon = isEdit ? <EditIcon /> : <PersonAddIcon />;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const {
		control,
		register,
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = useForm<RhymeUser>({
		defaultValues: {
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
			userName: user?.userName || '',
			roles: user?.roles || [],
		},
		resolver: zodResolver(getUserFormSchema(approvedEmailDomains)),
		mode: 'onBlur',
	});

	useEffect(() => {
		//reset form defaults when user changes
		reset(user);
	}, [user]);

	//Once the user clicks Save, we  go into
	//the "isLoading" state. This allows us to lock the
	//Save/Cancel buttons and give the user visual feedback
	//that things are happening. Once we reload the users after
	//our save functionality, we close the dialog and a snackbar will
	//be displayed.
	useEffect(() => {
		if (!loading) {
			closeDialog();
		}
	}, [loading]);

	const closeDialog = () => {
		setIsOpen(false);
		reset(user);
	};

	const onSubmit = (data: RhymeUser) => {
		submitUser(data);
	};

	const textFieldInputs: Array<UserFormInput> = [
		{ fieldKey: 'firstName', label: 'First Name', gridXs: 6 },
		{ fieldKey: 'lastName', label: 'Last Name', gridXs: 6 },
		{
			fieldKey: 'userName',
			label: 'Email Address',
			gridXs: 12,
			props: { disabled: isEdit },
		},
	];

	return (
		<>
			<Button
				size={isEdit ? 'small' : 'large'}
				startIcon={buttonIcon}
				onClick={() => setIsOpen(true)}
			>
				{dialogTitleText}
			</Button>
			<Dialog open={isOpen}>
				<DialogTitle color="primary">{dialogTitleText}</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="org-details"
									label="Organization"
									defaultValue={`${name} (${id})`}
									disabled
								/>
							</Grid>
							{textFieldInputs.map((field, index) => {
								return (
									<Grid item xs={field.gridXs} key={index}>
										<TextField
											{...register(field.fieldKey)}
											label={field.label}
											error={!!errors[field.fieldKey]}
											helperText={errors[field.fieldKey]?.message}
											fullWidth
											{...field.props}
										/>
									</Grid>
								);
							})}
							<Grid item xs={12}>
								<UserRoleAutcomplete
									control={control}
									activeEntity={activeEntity}
									errors={errors}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button type="submit" startIcon={<CheckCircleIcon />} disabled={loading || !isValid}>
							{loading ? 'Saving...' : 'Save'}
						</Button>
						<Button onClick={() => closeDialog()} startIcon={<CancelIcon />} disabled={loading}>
							Cancel
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default AddOrEditUserDialog;
