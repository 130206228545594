import { RhymeUser } from 'type-declarations';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
	Chip,
	Stack,
} from '@mui/material';
import { FC, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
	user?: RhymeUser;
	btnText: string;
	btnIcon: React.ReactNode;
	title: string;
	description?: string;
	callback: (email: string) => void;
};

const UserDialog: FC<Props> = ({ user, btnText, btnIcon, title, description, callback }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const userEmail = user?.userName;

	const handleUser = () => {
		if (userEmail !== undefined) {
			callback(userEmail);
			setIsOpen(!isOpen);
		}
	};

	return (
		<>
			<Button size="small" startIcon={btnIcon} onClick={() => setIsOpen(!isOpen)}>
				{btnText}
			</Button>
			<Dialog open={isOpen}>
				<DialogTitle
					sx={{
						justifyContent: 'flex-end',
						display: 'flex',
						p: 0,
					}}
				>
					<IconButton onClick={() => setIsOpen(!isOpen)} aria-label="close-dialog">
						{' '}
						<ClearIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent sx={{ mt: 0 }}>
					<Typography variant="h6" color="primary">
						{title}
					</Typography>
					{description && (
						<Typography variant="body2" color="text.secondary">
							{description}
						</Typography>
					)}
					{user && (
						<Stack direction="row" justifyContent="center" marginY={1} spacing={1}>
							<Chip label={`${user.firstName} ${user.lastName}`} />
							<Chip label={userEmail} />
						</Stack>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleUser} startIcon={<CheckCircleIcon />}>
						Confirm
					</Button>
					<Button onClick={() => setIsOpen(!isOpen)} startIcon={<CancelIcon />}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UserDialog;
