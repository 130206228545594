import { createSlice } from '@reduxjs/toolkit';
import { SliceStatus } from 'type-declarations';
import { detachFile, loadS3SourceUrl } from './thunks';

export interface FileUploadState {
	fileSubmitted: boolean;
	note: string;
	status: SliceStatus;
	isErrored: boolean;
	deleteInProgress: boolean;
}

const initialState: FileUploadState = {
	fileSubmitted: false,
	note: '',
	status: SliceStatus.IDLE,
	isErrored: false,
	deleteInProgress: false,
};

export const fileUploadSlice = createSlice({
	name: 'file-upload',
	initialState,
	reducers: {
		fileUploadIsErrored: (state, action) => {
			state.isErrored = action.payload;
		},
		filesAreSubmitted: (state, action) => {
			state.fileSubmitted = action.payload;
		},
		statusUpdate: (state, action) => {
			state.status = action.payload;
		},
		saveNoteInState: (state, action) => {
			state.note = action.payload;
		},
		resetFileUpload: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadS3SourceUrl.fulfilled, (state) => {
			state.fileSubmitted = true;
		});
		builder.addCase(loadS3SourceUrl.rejected, (state) => {
			state.fileSubmitted = false;
		});
		//* Detach file
		builder.addCase(detachFile.pending, (state) => {
			state.deleteInProgress = true;
		});
		builder.addCase(detachFile.fulfilled, (state) => {
			state.deleteInProgress = false;
		});
		builder.addCase(detachFile.rejected, (state) => {
			state.deleteInProgress = false;
		});
	},
});

export const fileUploadReducer = fileUploadSlice.reducer;
export const fileUploadActions = fileUploadSlice.actions;
export const {
	fileUploadIsErrored,
	filesAreSubmitted,
	statusUpdate,
	resetFileUpload,
	saveNoteInState,
} = fileUploadActions;
