import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get, post } from 'aws-amplify/api';
import {
	AddressChangeRequest,
	AddressCorrectionType,
	CaseModel,
	DataGatheringRequiredResponse,
	Provider,
	ProviderSelection,
	ProviderSelectionResponse,
	RHYME_API_SERVICES,
	SdgSubmit,
	SubmitData,
} from 'type-declarations';
import { toCamelCase } from 'string-utilities';

const rhymeApiService = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];

export const fetchDataGatheringRequiredScreen = createAsyncThunk(
	'dg-required-thunk/fetchDataGatheringRequiredScreenThunk',
	async ({
		orgId,
		caseId,
		submissionId,
	}: {
		orgId: string;
		caseId: string;
		submissionId: string;
	}) => {
		try {
			const { body } = await get({
				apiName: rhymeApiService,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/required`,
			}).response;
			const data = await body.json();
			return data as unknown as DataGatheringRequiredResponse[];
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(
					`Response status of ${statusCode}, 
        dataGathering service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Data gathering failed with error: ${error}`);
		}
	}
);

export const submitSdgData = createAsyncThunk(
	'dg-required-thunk/submitSdgDataThunk',
	async ({
		orgId,
		caseId,
		submissionId,
		submitData,
	}: {
		orgId: string;
		caseId: string;
		submissionId: string;
		submitData: SdgSubmit;
	}) => {
		try {
			const options = {
				body: submitData as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/submit`,
				options,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        dataGathering service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Data gathering failed with error: ${error}`);
		}
	}
);

export const validateSdgData = createAsyncThunk(
	'dg-required-thunk/validateSdgData',
	async ({
		orgId,
		caseId,
		submissionId,
		correctionData,
	}: {
		orgId: string;
		caseId: string;
		submissionId: string;
		correctionData: Partial<SubmitData>;
	}) => {
		try {
			const params = {
				body: { dataToValidate: correctionData } as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/validate`,
				options: params,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        dataGathering service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Data gathering validation with error: ${error}`);
		}
	}
);

interface ProviderFetchData {
	activeEntityId: string;
	submission: string;
	additionalSetSize: number;
}

export const fetchAddressOptions = createAsyncThunk<ProviderSelection[], ProviderFetchData>(
	'dg-required-thunk/fetchAddressOptions',
	async ({ activeEntityId, submission, additionalSetSize }, { getState }) => {
		const appState = getState() as any;
		const { providerSearchTerms } = appState.dataGathering;
		const { case: caseDetails } = appState.case;
		const providerType = caseDetails.addressCorrections[0].addressType;
		const providerTypeCamelCase = toCamelCase(providerType);
		const preexistingProvider = caseDetails.referral[providerTypeCamelCase];
		const isRenderingFacility: boolean = providerType === AddressCorrectionType.RenderingFacility;
		const providerTypeString = isRenderingFacility ? 'facility' : 'provider';
		const params = createParams(
			additionalSetSize,
			providerSearchTerms,
			preexistingProvider,
			false,
			false
		);
		//First call********
		try {
			let providerSelections = await providerSelectionsNetworkCall(
				activeEntityId,
				caseDetails,
				submission,
				providerTypeString,
				params
			);
			//Second call *********
			if (providerSelections.length === 0) {
				if (isRenderingFacility) {
					const params = createParams(
						additionalSetSize,
						providerSearchTerms,
						preexistingProvider,
						false,
						true
					);
					providerSelections = await providerSelectionsNetworkCall(
						activeEntityId,
						caseDetails,
						submission,
						providerTypeString,
						params
					);
				}
				//Third call ********
				if (providerSelections.length === 0) {
					const tryWithAllOrgs = isRenderingFacility ? true : false;
					const params = createParams(
						additionalSetSize,
						providerSearchTerms,
						preexistingProvider,
						true,
						tryWithAllOrgs
					);
					providerSelections = await providerSelectionsNetworkCall(
						activeEntityId,
						caseDetails,
						submission,
						providerTypeString,
						params
					);
				}
			}
			return providerSelections;
		} catch (error) {
			throw new Error(`Fetch address option failed with error: ${error}`);
		}
	}
);

const providerSelectionsNetworkCall = async (
	activeEntityId: string,
	caseDetails: CaseModel,
	submissionId: string,
	providerType: string,
	params: any
): Promise<ProviderSelection[]> => {
	// eslint-disable-next-line max-len
	const getUrl = `organization/${activeEntityId}/case/${caseDetails.caseId}/submission/${submissionId}/payer-integration/${caseDetails.benefitManager.toLowerCase()}/${providerType}-selection`;

	const { body } = await get({
		apiName: rhymeApiService,
		path: getUrl,
		options: {
			queryParams: {
				...params,
			},
		},
	}).response;
	const data = (await body.json()) as unknown as ProviderSelectionResponse;
	return data.providerSelections as ProviderSelection[];
};

const createParams = (
	additionalSetSize: number,
	searchTerms: Partial<AddressChangeRequest>,
	preexistingProvider: Provider,
	tryWithoutTin: boolean,
	searchAllOrganizationTypes: boolean
) => {
	const resultSetSize = 50;
	const resultOffSet = (): number => (0 + resultSetSize) * additionalSetSize;
	const params: AddressChangeRequest = {
		npi: searchTerms?.npi || preexistingProvider?.npi,
		address: preexistingProvider?.address1,
		firstName: preexistingProvider['firstName'] || undefined,
		lastName: preexistingProvider['lastName'] || undefined,
		name: preexistingProvider['facilityName'] || preexistingProvider['name'] || undefined,
		taxId:
			searchTerms?.npi || searchTerms?.taxId || tryWithoutTin
				? searchTerms?.taxId
				: preexistingProvider['taxId'],
		searchAllOrganizationTypes: searchAllOrganizationTypes,
		offset: resultOffSet(),
		limit: resultSetSize,
		city: searchTerms?.city || undefined,
		state: searchTerms?.state || undefined,
		zip: searchTerms?.zip || undefined,
	};
	return Object.fromEntries(
		Object.entries(params)
			.filter(([key, value]) => value !== undefined)
			.map(([key, value]) => [key, String(value)])
	);
};
