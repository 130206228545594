export enum DocumentType {
	ClinicalDocument = 'clinicalDocument',
	PayerArtifact = 'payerArtifact',
	ClinicalNote = 'clinicalNote',
}
export enum FileDataStatus {
	PENDING = 'pending',
	UPLOADED = 'uploaded',
	SCANNING = 'scanning',
	INFECTED = 'infected',
	AVAILABLE = 'available',
	UNSUPPORTED = 'unsupported',
	ERROR = 'error',
	SUBMITTED = 'submitted',
	UMDIDNOTLIKETHISFILESOTHEYDIDNOTACCEPTIT = 'umDidNotLikeThisFileSoTheyDidNotAcceptIt',
	CANCELED = 'canceled',
	DELETED = 'deleted',
	WARNING = 'warning',
	// UI Only
	INITIAL = 'initial',
}

export const TerminalFileDataStatus: Array<string> = [
	FileDataStatus.INFECTED,
	FileDataStatus.SUBMITTED,
	FileDataStatus.ERROR,
	FileDataStatus.UMDIDNOTLIKETHISFILESOTHEYDIDNOTACCEPTIT,
	FileDataStatus.CANCELED,
	FileDataStatus.DELETED,
];

export interface UploadFileEntry {
	documentName: string;
	documentType: DocumentType;
	file?: File;
}

export interface CombineFileUploadFileEntry extends UploadFileEntry {
	fileDetails: File;
}

export interface UploadFileURIRequest {
	files: Array<UploadFileEntry>;
	batchId: string;
}

export interface FileToUpload {
	url: string;
	fileId: string;
	file?: File;
	id: number;
	submitted: boolean;
	isNote: boolean;
	validType: boolean;
	validSize: boolean;
	invalidMessage?: string;
	submissionFailure: boolean;
	// status?: FileDataStatus,
	organizationId?: string;
	size?: number; //Bytes
}

export interface UploadFileEntryResponse extends UploadFileEntry {
	uri: string;
	fileId: string;
}
export interface UploadFileURIResponseObject {
	files: Array<UploadFileEntryResponse>;
}

export interface FileData {
	fileId: string;
	name: string;
	documentType: string;
	dateReceived: string; // ISO Date Please
	status: FileDataStatus;
}

export interface NoteResponse {
	clinicalNoteFileId: string;
}
