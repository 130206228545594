import { AxiosResponse } from 'axios';
import { SnackbarManager } from 'rhyme-component-library';

export const downloadFileInBrowser = (
	s3Response: AxiosResponse<any, any> | { status: any; data: any; headers: any },
	fileName: string
) => {
	try {
		const url = window.URL.createObjectURL(
			new Blob([s3Response.data], { type: s3Response.headers['contentType'] })
		);
		const link = document.createElement('a');
		link.href = url;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		link.parentNode!.removeChild(link);
		window.URL.revokeObjectURL(url);
	} catch (error) {
		SnackbarManager.show({ message: `Unable to download file.`, type: 'error' });
		// eslint-disable-next-line no-console
		console.warn(`Unable to download file. Error: ${error}`);
	}
};
