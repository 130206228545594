import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { FC } from 'react';
import { createPartnerUser, deletePartnerUser, updatePartnerUser } from 'state-services';
import { EntityType, RhymeUser } from 'type-declarations';
import { payerColumns, providerColumns } from './constants';
import Users from './components/Users';
import { isStatusPendingOrRefreshing } from 'slice-utilities';

const AdminUsers: FC = () => {
	const dispatch = useAppDispatch();
	const { activeEntity } = useAppSelector((state) => state.user);
	const { partnerType } = activeEntity;

	const { users, status } = useAppSelector((state) => state.admin.partner.users);

	const createUser = (user: RhymeUser) => {
		dispatch(createPartnerUser({ user }));
	};

	const editUser = (user: RhymeUser) => {
		dispatch(updatePartnerUser({ user }));
	};

	const deleteUser = (email: string) => {
		dispatch(deletePartnerUser({ email }));
	};

	return (
		<>
			<Users
				users={users}
				loading={isStatusPendingOrRefreshing([status])}
				columns={partnerType === EntityType.PROVIDER_ORGANIZATION ? providerColumns : payerColumns}
				createUser={createUser}
				editUser={editUser}
				removeUser={deleteUser}
			/>
		</>
	);
};

export default AdminUsers;
