import {
	Authenticator,
	Button,
	Image,
	View,
	useAuthenticator,
	useTheme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify/utils';
import './rhymeAuthenticator.scss';
import React from 'react';
import RhymeLockup from '../../assets/rhyme_lockup.svg';
import { getAppEnv } from 'env-utilities';

I18n.putVocabulariesForLanguage('en', {
	'Sign in': 'SIGN IN', // Button label
	Email: 'Email Address *', // Username label
	Password: 'Password *', // Password label
});

type Props = {
	isRso?: boolean; //if true skip aws authenticator signin
	children: React.ReactNode;
};

const components = {
	SignIn: {
		Header() {
			const { tokens } = useTheme();
			return (
				<View textAlign="center" padding={tokens.space.large}>
					<Image alt="Rhyme Logo and Company Name" src={RhymeLockup} />
				</View>
			);
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();
			return (
				<View textAlign="center">
					<Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
						Forgot your password?
					</Button>
					<br />
					<Button
						marginBottom={20}
						fontWeight="normal"
						onClick={goToPortal}
						size="small"
						variation="link"
					>
						Looking for Rhyme Case Search?
					</Button>
					<p>
						Rhyme Portal: {process.env.REACT_APP_ENV} - {process.env.REACT_APP_ADO_BUILD_NUMBER}
					</p>
				</View>
			);
		},
	},
};

const appEnv = getAppEnv();

const goToPortal = () => {
	window.open(`https://${appEnv}.priorauthnow.com/`, '_blank');
};

export function RhymeAuthenticator(props: Props) {
	const { children, isRso = false } = props;

	return (
		<>
			{isRso ? (
				children
			) : (
				<Authenticator hideSignUp components={components} loginMechanisms={['email']}>
					{children}
				</Authenticator>
			)}
		</>
	);
}
