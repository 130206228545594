import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get } from 'aws-amplify/api';
import { RHYME_API_SERVICES } from 'type-declarations';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];

export const fetchPayerList = createAsyncThunk(
	'payerlist-thunk/fetchPayerListThunk',
	async () => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `ontology/payer/flat`,
			}).response;
			const data = await body.json();
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        MDM service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Fetch payer list failed with error: ${error}`);
		}
	}
);
