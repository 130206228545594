import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AdminUsers from './AdminUsers';
import DefaultContact from './provider/defaultContact/DefaultContact';
import FacilityRules from './provider/FacilityRules/FacilityRules';
import { AdminOption } from './types';
import {
	EntityType,
	PartnerEntity,
	RhymeUser,
	UserRoleTypes,
	getRolesDisplay,
} from 'type-declarations';
import { Chip, Stack } from '@mui/material';
import PersonRemove from '@mui/icons-material/PersonRemove';
import { GenericUserDialog } from '@app/shared/components/dialog/types';

export const payerAdminTabOptions: AdminOption[] = [
	{
		text: 'Users',
		path: '/admin/users',
		component: <AdminUsers />,
	},
];

export const providerAdminTabOptions: AdminOption[] = [
	{
		text: 'Users',
		path: '/admin/users',
		component: <AdminUsers />,
	},
	{
		text: 'Default Contact',
		path: '/admin/default-contact',
		component: <DefaultContact />,
	},
	{
		text: 'Facility Rules',
		path: '/admin/facility-rules',
		component: <FacilityRules />,
	},
];

export const getRoleTypes = (user: RhymeUser) => {
	if (user?.roles !== undefined && user?.roles.length > 0) {
		return getRolesDisplay(user.roles);
	}
	return [];
};

export const handleEntityOptions = (entity: PartnerEntity): UserRoleTypes[] => {
	let options: string[] = [];

	switch (entity.partnerType) {
		case EntityType.PROVIDER_ORGANIZATION:
			options = [
				UserRoleTypes.ProviderPARequester,
				UserRoleTypes.ProviderAdmin,
				UserRoleTypes.ReportViewer,
				UserRoleTypes.ProviderReportViewerPhi,
			];
			entity.noticeOfAdmissionEnabled
				? (options = [UserRoleTypes.ProviderNoaAdmin, ...options])
				: null;
			break;
		case EntityType.DELEGATED_BENEFIT_MANAGER:
			options = [UserRoleTypes.DelegatedBenefitManagerAdmin, UserRoleTypes.DbmReportViewerPhi];
			break;
		case EntityType.PAYER_INSTITUTION:
			options = [
				UserRoleTypes.PayerAdmin,
				UserRoleTypes.PayerReportViewer,
				UserRoleTypes.PayerReportViewerPhi,
			];
			break;
		default:
			break;
	}

	return options;
};

export const providerColumns: GridColDef[] = [
	{
		field: 'fullName',
		headerName: 'Name',
		description: "The user's first and last name.",
		sortable: true,
		minWidth: 180,
		flex: 1,
		valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
	},
	{
		field: 'role',
		headerName: 'Role(s)',
		description:
			'Roles determine what information a user can access and what actions they can take.',
		minWidth: 400,
		flex: 1,
		filterable: false,
		sortable: false,
		// TODO: prefer direction="column" but i cannot yet figure out
		// how to make cell grow in height with component inside.
		renderCell: (params: GridRenderCellParams) => (
			<Stack direction="row" spacing={0.5}>
				{getRoleTypes(params.row).map((role, index) => (
					<Chip label={role} size="small" key={index} />
				))}
			</Stack>
		),
	},
];

export const payerColumns: GridColDef[] = [
	{
		field: 'fullName',
		headerName: 'Name',
		description: "The user's first and last name.",
		sortable: true,
		minWidth: 180,
		flex: 1,
		valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
	},
	{
		field: 'userName',
		headerName: 'Email Address',
		description: "The user's email address.",
		sortable: true,
		minWidth: 180,
		flex: 1,
		valueGetter: (value, row) => `${row.userName || ''}`,
	},
	{
		field: 'role',
		headerName: 'Role(s)',
		description:
			'Roles determine what information a user can access and what actions they can take.',
		minWidth: 400,
		flex: 1,
		filterable: false,
		sortable: false,
		// TODO: prefer direction="column" but i cannot yet figure out
		// how to make cell grow in height with component inside.
		renderCell: (params: GridRenderCellParams) => (
			<Stack direction="row" spacing={0.5}>
				{getRoleTypes(params.row).map((role, index) => (
					<Chip label={role} key={index} size="small" />
				))}
			</Stack>
		),
	},
];

export const deleteUserDialog: GenericUserDialog = {
	btnText: 'Delete User',
	btnIcon: <PersonRemove />,
	title: 'Are you sure you want to delete this user?',
	description: "The deleted user will lose all access to your organization's Rhyme account.",
};
