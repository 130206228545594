import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { fetchDraft, confirmSubmission } from './thunks';
import { DraftModel, SliceStatus } from 'type-declarations';

export interface DraftState {
	draft: DraftModel;
	status: SliceStatus;
	isErrored: boolean;
	draftSubmitted: boolean;
}

const initialState: DraftState = {
	draft: {} as DraftModel,
	status: SliceStatus.IDLE,
	isErrored: false,
	draftSubmitted: false,
};

export const draftSlice = createSlice({
	name: 'draft',
	initialState,
	// Reducers to manually reset draft state
	reducers: {
		resetDraftSlice: (state) => {
			Object.assign(state, initialState);
		},
		resetDraftIsErrored: (state, action) => {
			state.isErrored = action.payload;
		},
	},
	// Thunks automatically dispatch these actions
	extraReducers: (builder) => {
		builder.addCase(fetchDraft.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchDraft.fulfilled, (state, action: PayloadAction<DraftModel>) => {
				// Compare existing & new state. Only updates on change, used to prevent DOM updates when polling.
				const statesEqual = JSON.stringify(current(state).draft) === JSON.stringify(action.payload);
				state.status = SliceStatus.SUCCEEDED;
				state.isErrored = false;
				if (!statesEqual) {
					// Reset & overwrite state
					state.draft = {} as DraftModel;
					state.draft = action.payload;
					state.draft.referral.transactionType = state.draft.transactionType;
				}
			}),
			builder.addCase(fetchDraft.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
				state.isErrored = true;
				state.draft = {} as DraftModel;
			}),
			builder.addCase(confirmSubmission.pending, (state) => {
				state.status = SliceStatus.REFRESHING;
				state.isErrored = false;
			}),
			builder.addCase(confirmSubmission.fulfilled, (state) => {
				state.status = SliceStatus.SUCCEEDED;
				state.isErrored = false;
				state.draftSubmitted = true;
			}),
			builder.addCase(confirmSubmission.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
				state.isErrored = true;
			});
	},
});

export const draftReducer = draftSlice.reducer;
export const draftActions = draftSlice.actions;
export const { resetDraftSlice, resetDraftIsErrored } = draftActions;
