/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NoteResponse, RHYME_API_SERVICES, UploadFileURIResponseObject } from 'type-declarations';
import {
	DocumentType,
	FileData,
	UploadFileEntryResponse,
	UploadFileURIRequest,
} from 'type-declarations';
import { SnackbarManager } from 'rhyme-component-library';
import Axios, { AxiosError } from 'axios';
import { setHeaderWithAmzTagging, setHeaderWithoutAmzTagging } from './utils/setRequestHeader';
import { setCanceledFiles } from './utils/setCanceledFiles';
import { ApiError, del, post } from 'aws-amplify/api';
import * as Sentry from '@sentry/react';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200];

// First step of file upload is to get s3 presigned url
export const fetchFileUploadUrlSource = createAsyncThunk(
	'file-upload/fetchFileUploadUrlSource',
	async ({
		fileArray,
		caseId,
		organizationId,
		batchId,
		store,
	}: {
		fileArray: File[];
		caseId: string;
		organizationId: string;
		batchId: string;
		store: any;
	}) => {
		const request: UploadFileURIRequest = {
			files: fileArray.map((f) => ({
				documentName: f!.name,
				documentType: DocumentType.ClinicalDocument,
			})),
			batchId: batchId,
		};
		const options = {
			body: { ...request } as unknown as FormData,
		};

		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/entity/${caseId}/upload-url`,
				options,
			}).response;
			//TODO: might need to create a interface instead any
			const data = (await body.json()) as unknown as UploadFileURIResponseObject;
			const allFilesIncludeUri = data
				? data.files.every((f: UploadFileEntryResponse) => !!f.uri)
				: null;
			if (allFilesIncludeUri) {
				return data;
			} else {
				await store
					.dispatch(
						submissionFailure({
							canceledFiles: setCanceledFiles(data.files),
							caseId,
							organizationId,
						})
					)
					.unwrap();
				return {} as UploadFileURIResponseObject;
			}
		} catch (error) {
			throw new Error(`Fetch file upload url failed with error: ${error}`);
		}
	}
);

// Use the s3 presigned url to save the file
export const loadS3SourceUrl = createAsyncThunk(
	'file-upload/loadS3SourceUrl',
	async ({
		fileResponses,
		caseId,
		organizationId,
		store,
	}: {
		fileResponses: UploadFileEntryResponse[];
		caseId: string;
		organizationId: string;
		store: any;
	}) => {
		const res = await Promise.all(
			fileResponses.map(async (f) => {
				return Axios.put(
					f.uri,
					f.file,
					f.uri.includes('x-amz-tagging')
						? setHeaderWithAmzTagging(f, organizationId)
						: setHeaderWithoutAmzTagging(f)
				).catch((err: AxiosError) => {
					console.error('Error occured when sending documents to S3 bucket.', {
						message: err.message,
						code: err.code,
						cause: err.cause,
						name: err.name,
						responseStatus: err.response?.status,
						requestDomain: err.config?.url?.split('?')[0],
					});
					Sentry.captureException(err);
					return null;
				});
			})
		);

		if (res && res.every((f) => f && validResponseCodes.includes(f.status))) {
			return res;
		} else {
			// Cancel attempted file uploads
			await store
				.dispatch(
					submissionFailure({
						canceledFiles: setCanceledFiles(fileResponses),
						caseId,
						organizationId,
					})
				)
				.unwrap();
			return null;
		}
	}
);

// Updating unscuccessful files status to canceled
export const submissionFailure = createAsyncThunk(
	'file-upload/submissionFailure',
	async ({
		canceledFiles,
		caseId,
		organizationId,
	}: {
		canceledFiles: FileData[];
		caseId: string;
		organizationId: string;
	}) => {
		const options = {
			body: { files: canceledFiles } as unknown as FormData,
		};

		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/case/${caseId}/file`,
				options,
			}).response;
			const data = await body.json();
			return data;
		} catch (error) {
			console.warn(error);
			return null;
		}
	}
);

// Uploads the clinical note. Slice updates handled by extraReducers on the slice
export const loadCaseNote = createAsyncThunk(
	'file-upload/loadCaseNote',
	async ({
		note,
		organizationId,
		caseId,
		batchId,
	}: {
		note: string;
		organizationId: string;
		caseId: string;
		batchId: string;
	}): Promise<NoteResponse> => {
		const options = {
			body: { clinicalNote: note, batchId } as unknown as FormData,
		};

		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/case/${caseId}/clinical-note`,
				options,
			}).response;
			const data = await body.json();
			return data as unknown as NoteResponse;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(
					`Response status of ${statusCode}, 
        file service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Load case note failed with error: ${error}`);
		}
	}
);

// Detach file with fileId
export const detachFile = createAsyncThunk(
	'file-upload/detachFile',
	async ({
		fileId,
		organizationId,
		caseId,
	}: {
		fileId: string;
		organizationId: string;
		caseId: string;
	}) => {
		try {
			const delOperation = await del({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/case/${caseId}/file/${fileId}`,
			});
			await delOperation.response;
		} catch (error) {
			console.warn(error);
			SnackbarManager.show({
				message: `An Error occurred when attempting to delete a file.`,
				type: 'error',
			});
			return null;
		}
	}
);
