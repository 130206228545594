import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UserDetails from './UserDetails';
import { RhymeUser } from 'type-declarations';
import AddOrEditUserDialog from './AddOrEditUserDialog';

type Props = {
	columns: GridColDef[];
	users: RhymeUser[];
	loading: boolean;
	createUser: (user: RhymeUser) => void;
	editUser: (user: RhymeUser) => void;
	removeUser: (email: string) => void;
};

const Users: FC<Props> = ({ columns, users, loading, createUser, editUser, removeUser }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [userList, setUserList] = useState<RhymeUser[]>([] as RhymeUser[]);
	const [selectedUserId, setSelectedUserId] = useState<string>('');

	const handleSelectedUser = (id: GridRowSelectionModel) => {
		setSelectedUserId(id.toString());
	};

	//filters users based searchTerm and switch options
	useEffect(() => {
		const noDuplicatesUserList: Array<RhymeUser> = [];
		users.forEach((user) => {
			const isDuplicate = noDuplicatesUserList.some((existingUser) => existingUser.id === user.id);
			if (!isDuplicate && user.id.length > 0) {
				noDuplicatesUserList.push(user);
			}
		});
		let userList = noDuplicatesUserList;
		if (searchTerm.length > 0) {
			userList = users.filter((user: RhymeUser) => JSON.stringify(user).indexOf(searchTerm) !== -1);
		}
		setUserList(userList);
	}, [users, searchTerm]);

	return (
		<Box height="100%" width="100%" className="qa-user-list">
			<Box display="flex">
				<Box sx={{ width: '175px', mr: 2, mb: 2 }}>
					<AddOrEditUserDialog isEdit={false} submitUser={createUser} loading={loading} />
				</Box>
				<TextField
					fullWidth
					id="search-users"
					label="Search"
					variant="outlined"
					size="small"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					onInput={(e) => {
						setSearchTerm((e.target as HTMLTextAreaElement).value);
					}}
				/>
			</Box>
			{/* users table */}
			<Box display="flex" width="100%">
				<DataGrid
					autoHeight
					rows={userList}
					getRowId={(row) => row.id}
					columns={columns}
					loading={loading && userList.length === 0}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
						sorting: {
							sortModel: [{ field: 'fullName', sort: 'asc' }],
						},
					}}
					pageSizeOptions={[10, 15]}
					onRowSelectionModelChange={(id) => handleSelectedUser(id)}
					sx={{ width: '60%', marginRight: 2 }}
				/>
				{/* user detail table */}
				<Box width="40%">
					{selectedUserId.trim().length > 0 && (
						<UserDetails
							userId={selectedUserId}
							users={users}
							submitUser={editUser}
							loading={loading}
							removeUser={removeUser}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Users;
