import { createSlice } from '@reduxjs/toolkit';
import { RhymeUser, SliceStatus } from 'type-declarations';
import { fetchPartnerUsers } from './thunks';

export interface PartnerUsersState {
	status: SliceStatus;
	users: RhymeUser[];
}

const initialState: PartnerUsersState = {
	status: SliceStatus.IDLE,
	users: [],
};

export const partnerUsersSlice = createSlice({
	name: 'partnerUsersThunk',
	initialState,
	reducers: {
		resetPartnerUsers: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPartnerUsers.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchPartnerUsers.fulfilled, (state, action) => {
				state.status = SliceStatus.SUCCEEDED;
				state.users = action.payload;
			}),
			builder.addCase(fetchPartnerUsers.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			});
	},
});

export const partnerUsersReducer = partnerUsersSlice.reducer;
export const partnerUsersActions = partnerUsersSlice.actions;
export const { resetPartnerUsers } = partnerUsersActions;
