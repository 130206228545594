import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { PartnerEntity, RhymeUser, getRoleDisplay } from 'type-declarations';
import { handleEntityOptions } from '../constants';

interface UserRoleAutcompleteProps {
	control: Control<RhymeUser>;
	errors: FieldErrors<any>;
	activeEntity: PartnerEntity;
}

export const UserRoleAutcomplete: FC<UserRoleAutcompleteProps> = ({
	control,
	activeEntity,
	errors,
}) => {
	const roleOptions = activeEntity ? handleEntityOptions(activeEntity) : [];

	return (
		<Controller
			control={control}
			name="roles"
			render={({ field: { onChange, value } }) => (
				<Autocomplete
					multiple
					options={roleOptions}
					getOptionLabel={(option) => getRoleDisplay(option)}
					onChange={(_event, values) => onChange(values as string[])}
					value={value || []}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Roles"
							variant="outlined"
							error={!!errors.roles}
							helperText={(errors.roles?.message as string) ?? ''}
						/>
					)}
				/>
			)}
		/>
	);
};
