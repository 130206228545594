import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCapabilities } from 'user-utilities';
import { Entity, PartnerEntity, SliceStatus } from 'type-declarations';
import { fetchUser } from './';

export const userInitialState: UserState = {
	firstName: '',
	lastName: '',
	id: '',
	userName: '',
	activeEntity: {} as PartnerEntity,
	entities: [],
	idToken: '',
	preferredMfa: '',
	isRso: false,
	status: SliceStatus.IDLE,
};

export interface UserState {
	lastName: string;
	firstName: string;
	id: string;
	userName: string;
	activeEntity: PartnerEntity;
	entities: Entity[];
	idToken: string;
	preferredMfa: string;
	isRso: boolean; // rso signin
	status: SliceStatus;
}

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		saveRsoUserDetails: (state: UserState, action: PayloadAction<UserState>) => {
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.id = action.payload.id;
			state.userName = action.payload.userName;
			state.activeEntity = action.payload.activeEntity;
			state.entities = action.payload.entities;
			state.idToken = action.payload.idToken;
			state.preferredMfa = action.payload.preferredMfa;
			state.isRso = action.payload.isRso;
			state.status = action.payload.status;
		},
		updateUserToken: (state: UserState, action: PayloadAction<string>) => {
			state.idToken = action.payload;
		},
		logOut: (state: UserState) => {
			Object.assign(state, userInitialState);
		},
		loadActiveEntityDetails: (state: UserState, action) => {
			const activeCapabilities = getCapabilities(state.entities, action.payload.id);
			state.activeEntity = { ...action.payload, capabilities: activeCapabilities };
		},
		updateActiveEntity: (state: UserState, action) => {
			const capabilities = getCapabilities(state.entities, action.payload.id);
			state.activeEntity = { ...action.payload, capabilities };
		},
		setPreferredMfa: (state: UserState, action: PayloadAction<string>) => {
			state.preferredMfa = action.payload;
		},
		isRsoUser: (state: UserState, action: PayloadAction<boolean>) => {
			state.isRso = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUser.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchUser.fulfilled, (state, action: PayloadAction<UserState>) => {
				if (action.payload && action.payload != null) {
					state.status = SliceStatus.SUCCEEDED;
					state.firstName = action.payload.firstName;
					state.lastName = action.payload.lastName;
					state.id = action.payload.id;
					state.userName = action.payload.userName;
					state.activeEntity = action.payload.activeEntity;
					state.entities = action.payload.entities;
					state.idToken = action.payload.idToken;
					state.preferredMfa = action.payload.preferredMfa;
					state.isRso = action.payload.isRso;
				}
			});
		builder.addCase(fetchUser.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
	},
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
export const {
	saveRsoUserDetails,
	updateUserToken,
	logOut,
	loadActiveEntityDetails,
	updateActiveEntity,
	setPreferredMfa,
	isRsoUser,
} = userActions;
