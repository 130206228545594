import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { fetchCase, getCanWithdraw, submitCase, submitClinicalDocs } from './thunks';
import { CanWithdrawResponse, CaseModel, SliceStatus } from 'type-declarations';

export interface CaseState {
	case: CaseModel;
	caseSubmitted: boolean; //true when submitting updates to case
	status: SliceStatus;
	canWithdraw: CanWithdrawResponse;
}

const initialState: CaseState = {
	case: {} as CaseModel,
	caseSubmitted: false,
	status: SliceStatus.IDLE,
	canWithdraw: {} as CanWithdrawResponse,
};

export const caseSlice = createSlice({
	name: 'caseThunk',
	initialState,
	reducers: {
		resetCaseSlice: (state) => {
			Object.assign(state, initialState);
		},
		resetCase: (state) => {
			state.case = {} as CaseModel;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCase.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchCase.fulfilled, (state, action: PayloadAction<CaseModel>) => {
				// Compare existing & new state. Only updates on change, used to prevent DOM updates when polling.
				const statesEqual = JSON.stringify(current(state).case) === JSON.stringify(action.payload);
				if (!statesEqual) {
					// Reset & overwrite state
					state.case = {} as CaseModel;
					state.caseSubmitted = false;
					state.case = action.payload;
				}
				state.status = SliceStatus.SUCCEEDED;
			}),
			builder.addCase(fetchCase.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
				state.case = {} as CaseModel;
			});
		builder.addCase(submitClinicalDocs.pending, (state) => {
			state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(submitClinicalDocs.fulfilled, (state) => {
				state.status = SliceStatus.SUCCEEDED;
			});
		builder.addCase(submitClinicalDocs.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		builder.addCase(submitCase.pending, (state) => {
			state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(submitCase.fulfilled, (state) => {
				state.caseSubmitted = true;
				state.status = SliceStatus.SUCCEEDED;
			});
		builder.addCase(submitCase.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		builder.addCase(getCanWithdraw.fulfilled, (state, action) => {
			const statesEqual =
				JSON.stringify(current(state).canWithdraw) === JSON.stringify(action.payload);
			if (!statesEqual) {
				state.canWithdraw = {} as CanWithdrawResponse;
				state.canWithdraw = action.payload;
			}
		});
		builder.addCase(getCanWithdraw.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
	},
});

export const caseReducer = caseSlice.reducer;
export const caseActions = caseSlice.actions;
export const { resetCaseSlice, resetCase } = caseActions;
