export class UserRoleTypes {
	static PAReadOnly = 'paReadOnly';
	static CustomerUpdatesOnly = 'customerUpdatesOnly';
	static ProviderAdmin = 'ProviderAdmin';
	static ProviderNoaAdmin = 'ProviderNoaAdmin';
	static ReportViewer = 'ProviderReportViewer';
	static ProviderReportViewerPhi = 'ProviderReportViewerPhi';
	static AuthenticatedUser = 'AuthenticatedUser';
	static PayerAdmin = 'PayerAdmin';
	static PayerReportViewer = 'PayerReportViewer';
	static PayerReportViewerPhi = 'PayerReportViewerPhi';
	static DelegatedBenefitManagerAdmin = 'DbmAdmin';
	static DbmReportViewer = 'DbmReportViewer';
	static ProviderPARequester = 'ProviderPaRequester';
	static DbmReportViewerPhi = 'DbmReportViewerPhi';

	static StandardAdminRoles() {
		return [this.PAReadOnly, this.CustomerUpdatesOnly, this.ReportViewer];
	}
}

export interface DescriptiveRole {
	description: string;
	display: string;
	role: string;
}

export const DescriptiveStandardAdminRoles: DescriptiveRole[] = [
	{
		role: UserRoleTypes.PAReadOnly,
		description: 'Freshly created user of the organization.',
		display: 'Read Only',
	},
	{
		role: UserRoleTypes.CustomerUpdatesOnly,
		description:
			"User will only receive emails from Rhyme's Customer Success team. No active Rhyme account will be created.",
		display: 'Customer Updates',
	},
	{
		role: UserRoleTypes.ProviderPARequester,
		description: 'Submit requests to payers via data gathering, clinical, and survey actions.',
		display: 'Prior Auth Requester',
	},
	{
		role: UserRoleTypes.ReportViewer,
		description: 'User has access to view reports.',
		display: 'Report Viewer',
	},
	{
		role: UserRoleTypes.ProviderReportViewerPhi,
		description: 'User has access to view reports, including reports containing PHI.',
		display: 'PHI Report Viewer',
	},
	{
		role: UserRoleTypes.ProviderAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal Admin',
	},
	{
		role: UserRoleTypes.ProviderNoaAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal NOA Admin',
	},
	{
		role: UserRoleTypes.AuthenticatedUser,
		description: 'No privliges in legacy system.',
		display: 'Authenticated User',
	},
	{
		role: UserRoleTypes.PayerAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal Admin',
	},
	{
		role: UserRoleTypes.PayerReportViewer,
		description: 'User has access to view reports.',
		display: 'Report Viewer',
	},
	{
		role: UserRoleTypes.PayerReportViewerPhi,
		description: 'User has access to view reports, including reports containing PHI.',
		display: 'PHI Report Viewer',
	},
	{
		role: UserRoleTypes.DelegatedBenefitManagerAdmin,
		description: 'Access to the portal admin page.',
		display: 'Portal Admin',
	},
	{
		role: UserRoleTypes.DbmReportViewer,
		description: 'User has access to view reports.',
		display: 'Report Viewer',
	},
	{
		role: UserRoleTypes.DbmReportViewerPhi,
		description: 'User has access to view reports, including reports containing PHI.',
		display: 'PHI Report Viewer',
	},
];

export const getRolesDisplay = (roles: Array<string>) => {
	const rolesArray: Array<string> = [];
	if (roles) {
		roles.forEach((role) => {
			const displayFriendlyRole = getRoleDisplay(role);
			if (displayFriendlyRole) {
				rolesArray.push(displayFriendlyRole);
			}
		});
	}
	return rolesArray;
};

export const getRoleDisplay = (role: UserRoleTypes): string => {
	return (
		(DescriptiveStandardAdminRoles.find((rt) => rt.role === role && role)?.display as string) ||
		(role as string)
	);
};
