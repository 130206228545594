import { v4 as uuidv4 } from 'uuid';

export function getUuid(): string {
	return uuidv4();
}

export function toDisplayString(aString: string): string {
	return !aString || aString.length <= 0
		? ''
		: aString
				.replace(/[_-]/g, ' ')
				.toLowerCase()
				.split(' ')
				.map((word) => word[0].toUpperCase() + word.substring(1))
				.join(' ');
}

// "HELLO_WORLD" => "helloWorld"
export const toCamelCase = (string: string): string => {
	return (
		string
			.toLowerCase() // Convert the entire string to lowercase
			.split('_') // Split the string by underscores
			// eslint-disable-next-line max-len
			.map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))) // Capitalize the first letter of each word except the first
			.join('')
	); // Join the words back together
};
