import { ListItemButton, ListItemText, Menu, MenuItem, MenuList, ListItem } from '@mui/material';
import React, { useEffect } from 'react';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import {
	fetchProviderFacilities,
	restFacilities,
	updateActiveEntity,
	fetchProviderDefaultContact,
	fetchPartnerUsers,
} from 'state-services';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { resetDefaultContact, resetPartnerUsers } from 'state-services';
import { EntityTypeActions } from 'user-utilities';
import { EntityGroup, EntityOption, PartnerEntity, UserRoleTypes } from 'type-declarations';

const SelectOrganizationMenu: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { partners } = useAppSelector((state) => state.partner);
	const { activeEntity, entities } = useAppSelector((state) => state.user);
	const { id, partnerType } = activeEntity;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const currentOrg = entities.find((e) => e.entityId === id);

	const handleOrgChange = (entity: EntityOption) => {
		const selectedEntity = partners.find((p) => p.id === entity.id) || ({} as PartnerEntity);
		dispatch(updateActiveEntity(selectedEntity));
		setAnchorEl(null);
		navigate('/');
	};

	const getEntityList = () => {
		const entityList: EntityOption[] = [];
		if (partners.length > 0) {
			partners.map((e) => {
				entityList.push({
					id: e.id,
					name: e.name,
					entityType: e.partnerType,
				});
			});
		}

		return entityList;
	};

	/**
	 * only call admin API's if user is
	 * providerAdmin or payerAdmin
	 */
	useEffect(() => {
		if (
			currentOrg?.roles.includes(UserRoleTypes.ProviderAdmin) ||
			currentOrg?.roles.includes(UserRoleTypes.PayerAdmin) ||
			currentOrg?.roles.includes(UserRoleTypes.DelegatedBenefitManagerAdmin)
		) {
			EntityTypeActions.isInEntityGroup(partnerType, EntityGroup.NOT_ORGANIZATION)
				? dispatch(fetchPartnerUsers())
				: (dispatch(fetchPartnerUsers()),
					dispatch(fetchProviderDefaultContact()),
					dispatch(fetchProviderFacilities()));
		} else {
			EntityTypeActions.isInEntityGroup(partnerType, EntityGroup.NOT_ORGANIZATION)
				? dispatch(resetPartnerUsers())
				: (dispatch(resetDefaultContact()),
					dispatch(restFacilities()),
					dispatch(resetPartnerUsers()));
		}
	}, [activeEntity]);

	return (
		<>
			<ListItem>
				<ListItemButton
					id="basic-button"
					aria-controls={open ? 'organization-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					<ListItemText>Switch Organization</ListItemText>
					<ChevronRightIcon />
				</ListItemButton>
			</ListItem>
			<Menu
				id="organization-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
			>
				<MenuList dense>
					{getEntityList().length > 0 &&
						getEntityList().map((e) => {
							return (
								<MenuItem
									dense
									key={e.id}
									selected={e.id === id}
									onClick={() => handleOrgChange(e)}
								>
									{e.name}
								</MenuItem>
							);
						})}
				</MenuList>
			</Menu>
		</>
	);
};

export default SelectOrganizationMenu;
