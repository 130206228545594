import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import LighthouseTabs from './LighthouseTabs';
import LighthouseOrganizationSelect from './LighthouseOrganizationsSelect';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import { EmbedDashboardUrlResponse, EntityType, PartnerEntity } from 'type-declarations';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { printLighthouseDashboard, resetLighthouseDashboard } from 'state-services';

interface Props {
	embedUrlsDetails: EmbedDashboardUrlResponse[];
	activeEntity: PartnerEntity;
	partners: PartnerEntity[];
	disabled: boolean;
}

const LighthouseToolbar: FC<Props> = ({ embedUrlsDetails, activeEntity, partners, disabled }) => {
	const dispatch = useAppDispatch();
	const { selectedDashboardId } = useAppSelector((state) => state.lighthouse);

	const resetDashboard = () => {
		dispatch(resetLighthouseDashboard(selectedDashboardId));
	};

	const printDashboard = () => {
		dispatch(printLighthouseDashboard(selectedDashboardId));
	};

	return (
		<Grid container flexDirection="row-reverse" justifyContent="space-between">
			<Grid item xl={3} lg={6}>
				<Button
					size="small"
					startIcon={<RefreshIcon />}
					disabled={disabled}
					onClick={resetDashboard}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					Reset Dashboard
				</Button>
				<Button size="small" startIcon={<PrintIcon />} disabled={disabled} onClick={printDashboard}>
					Print Dashboard
				</Button>
			</Grid>
			<Grid item xl={3} lg={6}>
				{activeEntity.partnerType === EntityType.PROVIDER_ORGANIZATION && (
					<LighthouseOrganizationSelect partners={partners} disabled={disabled} />
				)}
			</Grid>
			<Grid item xl={6} lg={12} justifyContent="flex-end">
				<LighthouseTabs embedUrlsDetails={embedUrlsDetails} disabled={disabled} />
			</Grid>
		</Grid>
	);
};

export default LighthouseToolbar;
