import { DocumentType, FileDataStatus, FileData, UploadFileEntryResponse } from 'type-declarations';

export const setCanceledFiles = (files: UploadFileEntryResponse[]): FileData[] =>
	files.map((f) => ({
		fileId: f.fileId,
		status: FileDataStatus.CANCELED,
		documentType: DocumentType.ClinicalDocument,
		name: '',
		dateReceived: '',
	}));
