import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, del, get, patch, post } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import { RhymeUser, RHYME_API_SERVICES } from 'type-declarations';
import { getScopeKeyFromEntityType } from 'user-utilities';
import { resetPartnerUsers } from './slice';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchPartnerUsers = createAsyncThunk(
	'partner-users/fetchPartnerUser',
	async (_, { getState, dispatch }) => {
		const appState = getState() as any;
		const { id, partnerType } = appState.user.activeEntity;
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `user?${getScopeKeyFromEntityType(partnerType)}=${id}`,
			}).response;
			const data = body.json() as unknown as Array<RhymeUser>;
			return data;
		} catch (error) {
			dispatch(resetPartnerUsers());
			throw new Error(`Fetch partner user failed with error: ${error}`);
		}
	}
);

export const updatePartnerUser = createAsyncThunk(
	'partner-users/updatePartnerUser',
	async ({ user }: { user: RhymeUser }, { getState, dispatch }) => {
		const appState = getState() as any;
		const { id, partnerType } = appState.user.activeEntity;
		const params = {
			body: {
				...user,
			},
		};
		try {
			await patch({
				apiName: rhymeApiUrl,
				path: `user/${user.userName}?${getScopeKeyFromEntityType(partnerType)}=${id}`,
				options: params,
			}).response;
			SnackbarManager.show({ message: 'User updated', type: 'success' });
			dispatch(fetchPartnerUsers());
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to edit user.',
				type: 'error',
			});
			throw new Error(`Update partner user failed with error: ${error}`);
		}
	}
);

export const createPartnerUser = createAsyncThunk(
	'partner-user/createPartnerUser',
	async ({ user }: { user: RhymeUser }, { getState, dispatch }) => {
		const appState = getState() as any;
		const { id, partnerType } = appState.user.activeEntity;
		const params = {
			body: {
				...user,
			},
		};
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `user/${user.userName}?${getScopeKeyFromEntityType(partnerType)}=${id}`,
				options: params,
			}).response;
			SnackbarManager.show({ message: 'User added.', type: 'success' });
			dispatch(fetchPartnerUsers());
		} catch (error) {
			let errorMessage = 'Failed to create user';
			if (error instanceof ApiError && error.response) {
				const { body } = error.response;
				if (body) {
					const data = JSON.parse(body);
					if (data.errors.length > 0) errorMessage = data.errors[0].message;
				}
			}
			SnackbarManager.show({
				message: errorMessage,
				type: 'error',
			});
			throw new Error(`Create partner user failed with error: ${error}`);
		}
	}
);

export const deletePartnerUser = createAsyncThunk(
	'partner-user/deletePartnerUser',
	async ({ email }: { email: string }, { getState, dispatch }) => {
		const appState = getState() as any;
		const { id, partnerType } = appState.user.activeEntity;

		try {
			await del({
				apiName: rhymeApiUrl,
				path: `user/${email}?${getScopeKeyFromEntityType(partnerType)}=${id}`,
			}).response;
			SnackbarManager.show({ message: 'User deleted.', type: 'success' });
			dispatch(fetchPartnerUsers());
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to delete user.',
				type: 'error',
			});
			throw new Error(`Delete partner failed with error: ${error}`);
		}
	}
);

export const resendPartnerUserInvite = createAsyncThunk(
	'partner-user/resendPartnerUserInvite',
	async ({ userName }: { userName: string }, { getState }) => {
		const appState = getState() as any;
		const { id, partnerType } = appState.user.activeEntity;
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `user/${userName}/resend-invitation?${getScopeKeyFromEntityType(partnerType)}=${id}`,
			}).response;
			SnackbarManager.show({
				message: 'Confirmation email sent to user',
				type: 'success',
			});
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to resend user invite.',
				type: 'error',
			});
			throw new Error(`Resend partner user invite failed with error: ${error}`);
		}
	}
);
