import DefaultContacts from '@app/views/admin/provider/defaultContact/DefaultContact';
import { createBrowserRouter } from 'react-router-dom';
import ErrorNotFound from '@views/errorNotFound/ErrorNotFound';
import FacilityRules from '@app/views/admin/provider/FacilityRules/FacilityRules';
import ApplicationWrapper from '@app/views/ApplicationWrapper';
import { adminPage, lighthousePage, homePage } from './applicationPages';
import AdminUsers from '@app/views/admin/AdminUsers';

/* Routing Example:
- Default redirects to login screen (should be handled by auth guard later)
- login is a stub of the login page
- Header Layout section for all screens that show a header by default(IE: not login, not NotFound)
- Providers as an example header component
- ProvidersTwo showing the same component without the header is a catchall which sends them to not found like our current app.
*/
export const router = createBrowserRouter([
	{
		path: homePage.path,
		element: <ApplicationWrapper />,
		errorElement: <ErrorNotFound />,
		children: [
			{
				path: homePage.path,
				element: homePage.element,
				errorElement: homePage.errorElement,
			},
			{
				path: adminPage.path,
				element: adminPage.element,
				errorElement: adminPage.errorElement,
				children: [
					{
						path: '/admin/users',
						element: <AdminUsers />,
						errorElement: <ErrorNotFound />,
					},
					{
						path: '/admin/default-contact',
						element: <DefaultContacts />,
						errorElement: <ErrorNotFound />,
					},
					{
						path: '/admin/facility-rules',
						element: <FacilityRules />,
						errorElement: <ErrorNotFound />,
					},
				],
			},
			{
				path: lighthousePage.path,
				element: lighthousePage.element,
				errorElement: lighthousePage.errorElement,
			},
		],
	},
	{
		path: '*',
		element: <ErrorNotFound />,
	},
]);
