import {
	AddressChangeRequest,
	DataGatheringRequiredResponse,
	ProviderSelection,
	SliceStatus,
} from 'type-declarations';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAddressOptions, fetchDataGatheringRequiredScreen } from './thunks';

export interface DataGatheringState {
	dataGatheringDetails: DataGatheringRequiredResponse[];
	status: SliceStatus;
	providerOptions: ProviderSelection[];
	providerOptionsStatus: SliceStatus;
	providerSearchTerms: Partial<AddressChangeRequest>;
	newProviderSearch: boolean;
	viewedPrecheck: boolean;
}

const initialState: DataGatheringState = {
	dataGatheringDetails: [] as DataGatheringRequiredResponse[],
	status: SliceStatus.IDLE,
	providerOptions: [] as ProviderSelection[],
	providerOptionsStatus: SliceStatus.IDLE,
	providerSearchTerms: {
		npi: undefined,
		taxId: undefined,
		city: undefined,
		state: undefined,
		zip: undefined,
	},
	newProviderSearch: true,
	viewedPrecheck: false,
};

export const dataGatheringSlice = createSlice({
	name: 'dataGatheringThunk',
	initialState,
	// Reducers to manually reset case state
	reducers: {
		resetDataGatheringSlice: (state: DataGatheringState) => {
			Object.assign(state, initialState);
		},
		setDataGatheringDetails: (
			state: DataGatheringState,
			action: PayloadAction<DataGatheringRequiredResponse[]>
		) => {
			state.dataGatheringDetails = action.payload;
		},
		setProviderSearchTerms: (state, action) => {
			state.providerSearchTerms = action.payload;
		},
		setNewProviderSearch: (state, action) => {
			state.newProviderSearch = action.payload;
		},
		setViewedPrecheck: (state, action) => {
			state.viewedPrecheck = action.payload;
		},
		resetViewedPrecheck: (state) => {
			state.viewedPrecheck = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDataGatheringRequiredScreen.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchDataGatheringRequiredScreen.fulfilled, (state) => {
				state.status = SliceStatus.SUCCEEDED;
			}),
			builder.addCase(fetchDataGatheringRequiredScreen.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			});
		builder.addCase(fetchAddressOptions.pending, (state) => {
			state.providerOptionsStatus = SliceStatus.PENDING;
		}),
			builder.addCase(fetchAddressOptions.fulfilled, (state, action) => {
				if (state.newProviderSearch === true) {
					state.newProviderSearch = false;
					state.providerOptions = action.payload || [];
				} else {
					if (
						action.payload &&
						JSON.stringify(action.payload) !== JSON.stringify(state.providerOptions)
					)
						state.providerOptions = [...state.providerOptions, ...action.payload];
				}
				state.providerOptionsStatus = SliceStatus.SUCCEEDED;
			}),
			builder.addCase(fetchAddressOptions.rejected, (state) => {
				state.providerOptionsStatus = SliceStatus.REJECTED;
			});
	},
});

export const DataGatheringReducer = dataGatheringSlice.reducer;
export const DataGatheringActions = dataGatheringSlice.actions;
export const {
	resetViewedPrecheck,
	resetDataGatheringSlice,
	setDataGatheringDetails,
	setProviderSearchTerms,
	setNewProviderSearch,
	setViewedPrecheck,
} = DataGatheringActions;
