import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, del, get, post } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import { RHYME_API_SERVICES, Referral, CanWithdrawResponse, CaseModel } from 'type-declarations';
import * as Sentry from '@sentry/react';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];
const validConfirmSubmissionCodes = [200, 409, 422, 424];
const validWithdrawCodes = [200, 205, 409];

//TODO need to verify if validResponse check returns response
export const fetchCase = createAsyncThunk(
	'case-thunk/fetchCaseThunk',
	async ({ activeEntityId, id }: { activeEntityId: string; id: string }) => {
		const getUrl = id.startsWith('C-')
			? `organization/${activeEntityId}/case/${id}`
			: `organization/${activeEntityId}/case?partnerCaseId=${id}`;

		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: getUrl,
			}).response;
			const data = (await body.json()) as unknown as CaseModel;
			Sentry.setTag('partnerCaseID', data.partnerCaseId);
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        case service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Fetch case failed with error: ${error}`);
		}
	}
);

export const submitCase = createAsyncThunk(
	'case-thunk/submitCaseThunk',
	async ({
		orgId,
		partnerCaseId,
		benefitManagerId,
		referral,
	}: {
		orgId: string;
		partnerCaseId: string;
		benefitManagerId: string;
		referral: Referral;
	}) => {
		const options = {
			body: { benefitManagerId: benefitManagerId, referral: referral } as unknown as FormData,
		};

		const submitCasePath = `organization/${orgId}/case?partnerCaseId=${partnerCaseId}&validation=false`;

		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: submitCasePath,
				options,
			}).response;
			return await body.json();
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to submit case.',
				type: 'error',
			});
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;

				throw new Error(
					`Response status of ${statusCode}, 
        case service expects${validConfirmSubmissionCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Submit case failed with error: ${error}`);
		}
	}
);

export const submitClinicalDocs = createAsyncThunk(
	'caseThunk/submitClinicalDocs',
	async ({ orgId, caseId }: { orgId: string; caseId: string }) => {
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/submit-files`,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        case service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Submit clinical documents failed with error: ${error}`);
		}
	}
);

export const getCanWithdraw = createAsyncThunk(
	'caseThunk/getWithdrawEnabled',
	async ({ orgId, caseId }: { orgId: string; caseId: string }) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/withdraw`,
			}).response;
			const data = (await body.json()) as unknown as CanWithdrawResponse;
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;

				throw new Error(
					`Response status of ${statusCode}, 
        case service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Get can withdraw failed with error: ${error}`);
		}
	}
);

export const userResponseWithdraw = createAsyncThunk(
	'caseThunk/userResponseWithdraw',
	async ({
		orgId,
		caseId,
		withdrawReason,
		withdrawId,
	}: {
		orgId: string;
		caseId: string;
		withdrawReason: string;
		withdrawId: string;
	}) => {
		try {
			const { statusCode } = await del({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/withdraw?userId=${withdrawId}&userInputReason=${withdrawReason}`,
			}).response;
			return statusCode;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				if (validWithdrawCodes.includes(statusCode)) {
					return statusCode;
				}
				throw new Error(
					`Response status of ${statusCode}, 
        case service expects${validWithdrawCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`User response withdraw failed with error: ${error}`);
		}
	}
);

export const withdrawCase = createAsyncThunk(
	'caseThunk/withdrawCase',
	async ({ orgId, caseId }: { orgId: string; caseId: string }) => {
		try {
			const { statusCode } = await del({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/withdraw`,
			}).response;
			return statusCode;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				if (validWithdrawCodes.includes(statusCode)) {
					return statusCode;
				}
				throw new Error(
					`Response status of ${statusCode},
				case service expects${validWithdrawCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Withdraw case failed with error: ${error}`);
		}
	}
);
